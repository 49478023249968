import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SessionProvider } from './Admin/SessionContext';
import React from 'react';
import TopNotice from './components/TopNotice';
import Navbar from './components/Navbar';
import ImageSlider from './components/ImageSlider';
import Feature from './components/Feature';
import SchlImgGallery from './components/SchlImgGallery';
import Footer from './components/Footer';
import TcForm from './components/TcForm';
import VideoGallery from './components/VideoGallery';
import ImageGallery from './components/ImageGallery';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import SchlTiming from './components/SchlTiming';
import FeeStructure from './components/FeeStructure';
import SmartClass from './components/SmartClass';
import Slab from './components/Slab';
import Slib from './components/lib';
import Ssports from './components/Ssports';
import Disclosure from './components/Disclosure';
import DocDnld from './components/DocDnld';
import ViewDocDnld from './components/ViewDocuDnld';
import ParentComponent from './components/parentsComponent';
import AdmissionForm from './components/AdmissionForm';
import LogIn from "./Admin/LogIn";
import AdminHome from './Admin/AdminHome';
import AdminNavbar from './Admin/AdminNavbar';
import NoticeForm from './Admin/NoticeForm';
import ViewNews from './Admin/ViewNews';
import ImgUploadForm from './Admin/ImgUploadForm';
import ViewBannerForm from './Admin/ViewBannerForm';
import SchlImgGalryUpldFrm from './Admin/SchlImgGalryUpldFrm';
import ViewSchlImgGalryFrm from './Admin/ViewSchlImgGalryFrm';
import TcUpldFrm from './Admin/TcUpldFrm';
import ViewTcFrm from './Admin/ViewTcFrm';
import DocumentDwnld from './Admin/DocumentDwnld';
import VideoUpldFrm from './Admin/VideoUpldFrm';
import ViewEnquiryData from './Admin/ViewEnquiryData';
import RegistrationData from './Admin/RegistrationData';
import StopTheServer from './components/StopTheServer';
import './App.css';
import './CSS/mobile.css';
import './CSS/ImageSlider.css'

function App() {
  const loggedIn = localStorage.getItem("isLoggedIn");
  return (
    <Router>
      <SessionProvider>
        <Routes>
          <Route path="/Admin/LogIn" element={<LogIn />} />
          <Route path="/Admin/AdminHome" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route exact path="/Admin/ImgUploadForm" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                    <ImgUploadForm />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route exact path="/Admin/ViewBannerForm" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                    <ViewBannerForm />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route exact path="/Admin/SchlImgGalryUpldFrm" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                    <SchlImgGalryUpldFrm />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route exact path="/Admin/ViewSchlImgGalryFrm" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                    <ViewSchlImgGalryFrm />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route exact path="/Admin/VideoUpldFrm" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                    <VideoUpldFrm />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route exact path="/Admin/NoticeForm" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                    <NoticeForm />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route exact path="/Admin/ViewNews" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                    <ViewNews />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route exact path="/Admin/TcUpldFrm" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                    <TcUpldFrm />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route exact path="/Admin/ViewTcFrm" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                    <ViewTcFrm />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route exact path="/Admin/DocumentDwnld" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                    <DocumentDwnld />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route exact path="/Admin/ViewEnquiryData" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                    <ViewEnquiryData />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route exact path="/Admin/RegistrationData" element={
            <>
              {loggedIn ? (
                <div className='app-container'>
                  <AdminHome />
                  <div className='content'>
                    <AdminNavbar />
                    <RegistrationData />
                  </div>
                </div>
              ) : (
                <LogIn />
              )}
            </>
          } />
          <Route path="/" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <ImageSlider />
              <Feature />
              <SchlImgGallery />
              <Footer />
            </>
          } />
          <Route path="/AboutUs" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <AboutUs />
              <Footer />
            </>
          } />
          <Route path="/ContactUs" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <ContactUs />
              <Footer />
            </>
          } />
          <Route path="/SmartClass" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <SmartClass />
              <Footer />
            </>
          } />
          <Route path="/Slab" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <Slab />
              <Footer />
            </>
          } />
          <Route path="/lib" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <Slib />
              <Footer />
            </>
          } />
          <Route path="/Ssports" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <Ssports />
              <Footer />
            </>
          } />
          <Route path="/VideoGallery" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <VideoGallery />
              <Footer />
            </>
          } />
          <Route path="/ImageGallery" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <ImageGallery />
              <Footer />
            </>
          } />
          <Route path="/SchlTiming" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <SchlTiming />
              <Footer />
            </>
          } />
          <Route path="/FeeStructure" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <FeeStructure />
              <Footer />
            </>
          } />
          <Route path="/ParentComponent" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <ParentComponent />
              <Footer />
            </>
          } />
          <Route path="/DocDnld" element={
            <>
              <DocDnld />
            </>
          } />
          <Route path="/viewDocDnld/:pdfName" component={ViewDocDnld} element={
            <>
              <ViewDocDnld />
            </>
          } />
          <Route path="/TcForm" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <TcForm />
              <Footer />
            </>
          } />
          <Route path="/Disclosure" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <Disclosure />
              <Footer />
            </>
          } />
          <Route path="/AdmissionForm" element={
            <>
              <TopNotice />
              <Navbar title="School Name" />
              <AdmissionForm />
              <Footer />
            </>
          } />
          <Route path="/StopTheServer" element={
            <>
              <StopTheServer />
            </>
          } />
        </Routes>
      </SessionProvider>
    </Router>
  );
}

export default App;
