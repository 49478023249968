import React from 'react'

export default function StopTheServer() {
    const fetchData = async () => {    
        try {
          const response = await fetch(`/api/stop-server`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          if (response.ok) {
          } else {
            console.error('Error fetching filtered data');
          }
        } catch (error) {
          console.error('Error fetching filtered data:', error);
        }
      };
    
      return (
        <div>
          <button onClick={fetchData}>Fetch Data</button>
        </div>
      );    
}